import React, { Component } from "react"
import Links from "./Links"

export class Secondary extends Component {
  render() {
    return (
      <div>
        <p>
          You can also support FishCat by ordering our Official Merch, and by
          adding us to your Amazon Smile.
        </p>
        <Links {...this.props} />
      </div>
    )
  }
}

export default Secondary
