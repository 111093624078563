import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Content from "./Content"

export class Item extends Component {
  classes = generateClasses.bind(this)({
    name: "item",
    variants: [this.props.id],
    elements: [
      {
        name: "body",
        elements: ["link", "note"],
      },
      "note",
    ],
  })

  getItemClass = () => {
    const { id } = this.props
    return this.classes.variants[id]
  }

  renderImageAndLabel = () => {
    const { src, alt, id } = this.props
    return (
      <Content
        src={src}
        id={id}
        alt={alt}
        parentClass={this.classes.elements.body.name}
      />
    )
  }

  render() {
    const { href, note } = this.props
    const imageAndLabel = this.renderImageAndLabel()
    const bodyElements = this.classes.elements.body.elements
    return (
      <li className={this.getItemClass()}>
        <div className={this.classes.elements.body.name}>
          <a href={href} className={bodyElements.link}>
            {imageAndLabel}
          </a>
        </div>
        <span className={this.classes.elements.note}>{note}</span>
      </li>
    )
  }
}

export default Item
