import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class PaymentError extends Component {
  classes = generateClasses.bind(this)({
    name: "payment-error",
    elements: ["title", "description"],
  })

  render() {
    return (
      <div className={this.classes.name} role="alert">
        <h4 className={this.classes.elements.title}>
          There was a problem with your donation.
        </h4>
        <p className={this.classes.elements.description}>
          Please check that your payment information is correct.
        </p>
      </div>
    )
  }
}

export default PaymentError
