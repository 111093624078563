import React from "react"
import { withStyles } from "@material-ui/core"

export const getStyles = () => {
  return {
    root: {
      border: "0.0625rem solid rgba(225, 228, 235, 1)",
      borderRadius: "0.375rem",
    },
  }
}

export const Container = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>
}

export default withStyles(getStyles)(Container)
