import React, { Component } from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import BaseImageWithCredit from "../../components/ImageWithCredit"
import { sectionIds } from "../../constants/sectionIds"
import { generateClasses } from "@supercoder.dev/bem-classes"
import Buttons from "./Buttons"

const Image = props => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "2020_EarthCamp_SrikanthMannepuri" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <GatsbyImage fluid={image.childImageSharp.fluid} {...props} />
}

const ImageWithCredit = props => (
  <BaseImageWithCredit
    {...props}
    credit="Photo: Srikanth Mannepuri for FishCat.org"
    Image={Image}
  />
)

export class Camps extends Component {
  render() {
    const classes = generateClasses({
      name: "earth-camps",
      elements: [
        "anchor",
        "image",
        {
          name: "content",
          elements: ["title", "description"],
        },
      ],
    })

    const normalHeadingStyles = { fontWeight: "300" }

    return (
      <div className={classes.name} Image={Image}>
        <div id={sectionIds.camps} className={classes.elements.anchor} />
        <ImageWithCredit className={classes.elements.image} />
        <div>
          <h2
            className={classes.elements.content.elements.title}
            style={{ paddingBottom: "25px", fontSize: "30px" }}
          >
            FishCat<span style={normalHeadingStyles}>.org</span>
            <span style={{ fontSize: "20px" }}>
              <sup>® </sup>
            </span>
            <span style={normalHeadingStyles}>Expeditions</span>
          </h2>
          <p style={{ marginBottom: "65px", fontSize: "20px" }}>
            Sign up as an explorer, and get guided by conservation professionals
            on life-changing wildlife experiences
          </p>
          <div>
            <a
              style={{
                border: "1px solid #ffd86d",
                borderRadius: "4px",
                color: "#212529",
                backgroundColor: "#ffd86d",
                borderColor: "#ffd86d",
                padding: "0.375rem 0.75rem",
                lineHeight: "1.5",
                fontSize: ".875rem",
                width: "100%",
                marginTop: "1.875rem",
                fontWeight: "700",
                textDecoration: "none",
              }}
              href="https://drive.google.com/drive/folders/14LmS_HhOjCXqakwM41-8tKMeM1OVcG_M"
              target="_blank"
              rel="noreferrer"
            >
              View Expeditions
            </a>
            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2022_FishCat_NewMexico.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2022_FishCat_Arizona.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2022_FishCat_agumbe.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2022_FishCat_glacier.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2022_FishCat_hemis.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9Am_lh41CXv6hoekW3iNS-zZSop7caLJP6Ug6HwlYLQqkWQ/viewform" target="blank"><img src="/2023_FishCat_Yala.png" alt="FishCat Expedition" style={{width: "15%", height: "15%", border: '2px solid white', borderRadius: "7px", marginRight: "25px"}}></img></a> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Camps
