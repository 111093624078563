import React from "react"
import {
  TextField as BaseTextField,
  createStyles,
  withStyles,
} from "@material-ui/core"

import { baseStyles, containerPadding, placeholderStyles } from "./styles"

const styles = () => {
  return createStyles({
    input: {
      ...baseStyles,
      padding: containerPadding,
      "&::placeholder": {
        ...placeholderStyles,
        opacity: 1,
      },
    },
  })
}

const TextField = ({
  classes,
  input: { value, onChange },
  meta,
  inputProps,
  InputProps,
}) => {
  return (
    <BaseTextField
      value={value}
      onChange={onChange}
      helperText={meta.error}
      error={Boolean(meta.error)}
      type="text"
      InputProps={{ disableUnderline: true, classes, ...InputProps }}
      fullWidth
      {...inputProps}
    />
  )
}

export default withStyles(styles)(TextField)
