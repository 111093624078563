import React from "react"
import { placeholderStyles, baseStyles } from "./styles"

const withStripeOptions = Element => {
  const options = {
    style: {
      base: {
        ...baseStyles,
        "::placeholder": {
          ...placeholderStyles,
        },
      },
    },
  }
  return props => <Element options={options} {...props} />
}

export default withStripeOptions
