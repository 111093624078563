import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

import { tiers } from "../../../../constants/investmentLevels"
import ChooseAmount from "./ChooseAmount/index"
import Item from "./Item/index"

export class InvestmentLevels extends Component {
  classes = generateClasses.bind(this)({
    name: "investment-levels",
    elements: ["list", "title"],
  })

  state = {
    showModal: false,
  }

  setShowModal = showModal => this.setState({ showModal })

  showModal = () => this.setShowModal(true)

  hideModal = () => this.setShowModal(false)

  renderItem = (itemData, index) => {
    const { setInvestmentLevel, selectedInvestmentLevel } = this.props
    return (
      <Item
        {...itemData}
        parentClass={this.classes.elements.list}
        key={index}
        setInvestmentLevel={setInvestmentLevel}
        selectedInvestmentLevel={selectedInvestmentLevel}
        modalId={this.modalId}
        showModal={this.showModal}
      />
    )
  }

  renderItems = () => tiers.map(this.renderItem)

  render() {
    const items = this.renderItems()
    const { selectedInvestmentLevel } = this.props
    const { showModal } = this.state
    return (
      <><div className="investmentLevelContainer">
        <ChooseAmount
          items={tiers}
          selectedInvestmentLevel={selectedInvestmentLevel}
          parentClass={this.classes.name}
          hideModal={this.hideModal}
          showModal={showModal}
          className="investmentLevelContainer"
        />
        <section className={this.classes.name}>
          <p className={this.classes.elements.title}>
            No fees, and 100% comes to us. Below are some investment levels
            along with their impact.
          </p>
          <ul className={this.classes.elements.list}>{items}</ul>
        </section>
        </div>
      </>
    )
  }
}

export default InvestmentLevels
