import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

const styles = () => {
  const size = "3rem"
  return createStyles({
    root: {
      backgroundColor: "rgba(240, 240, 240, 1)",
      borderRadius: "0.5rem",
      width: size,
      height: size,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "1.875rem",
    },
  })
}

const Avatar = ({ classes, icon }) => {
  return (
    <div className={classes.root}>
      <span className={classes.text}>{icon}</span>
    </div>
  )
}

export default withStyles(styles)(Avatar)
