import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

import { sectionIds } from "../../constants/sectionIds"
import Items from "./Items/index.js"

export default class Media extends Component {
  classes = generateClasses({
    name: "media",
    elements: ["anchor"],
  })

  render() {
    return (
      <div className={this.classes.name}>
        <div className={this.classes.elements.anchor} id={sectionIds.media} />
        <Items parentClass={this.classes.name} />
      </div>
    )
  }
}
