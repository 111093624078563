import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import { extendBaseClass } from "../utils/classes"

export class ImageWithCredit extends Component {
  baseClass = this.props.className

  extend = extendBaseClass.bind(this)

  creditClass = this.extend("credit")
  imageClass = this.extend("image")

  classes = generateClasses({
    name: this.props.className,
    elements: [
      {
        name: "credit",
        variants: ["top-left", "bottom-left", "top-right", "bottom-right"],
      },
    ],
  })

  getCreditVariant = () => {
    const { creditVariant = "bottom-left" } = this.props
    return this.classes.elements.credit.variants[creditVariant]
  }

  renderCredit = () => {
    const { profileLink, credit } = this.props
    if (profileLink) {
      return (
        <a className={this.getCreditVariant()} href={profileLink}>
          {credit}
        </a>
      )
    }
    return <span className={this.getCreditVariant()}>{credit}</span>
  }

  render() {
    const { Image } = this.props
    return (
      <div className={this.baseClass}>
        <Image className={this.imageClass} />
        {this.renderCredit()}
      </div>
    )
  }
}

export default ImageWithCredit
