import React from "react"
import { Typography, createStyles, withStyles } from "@material-ui/core"

import Logo from "./Logo"

const styles = () => {
  return createStyles({
    root: {
      marginBottom: "1.625rem",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: "2.125rem",
      fontFamily: "Montserrat, sans-serif",
      marginBottom: "0.625rem",
    },
    subtitle: {
      color: "rgba(169, 171, 189, 1)",
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  })
}

const Header = ({ classes, title, subtitle }) => {
  return (
    <div className={classes.root}>
      <Logo />
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      )}
    </div>
  )
}

export default withStyles(styles)(Header)
