import React from "react"
import { Button, Typography, createStyles, withStyles } from "@material-ui/core"

import Avatar from "./Avatar"

const styles = theme => {
  const fontFamily = "Montserrat, sans-serif"
  const selectedBorderColor = theme.palette.secondary.main
  const paddingY = "1.5625rem"
  return createStyles({
    root: {
      position: "relative",
      paddingTop: paddingY,
      paddingBottom: paddingY,
      borderWidth: "0.0625rem",
      borderStyle: "solid",
      justifyContent: "flex-start",
      textAlign: "left",
      "&:focus": {
        outlineColor: selectedBorderColor,
      },
      "& + &": {
        marginTop: "1.25rem",
      },
    },
    content: {
      display: "flex",
    },
    deselected: {
      borderColor: "rgba(225, 228, 235, 1)",
    },
    selected: {
      borderColor: selectedBorderColor,
      backgroundColor: "rgba(3, 158, 0, 0.04)",
    },
    body: {
      marginLeft: "1.125rem",
    },
    title: {
      marginBottom: "0.25rem",
      fontFamily,
      fontSize: "1rem",
      lineHeight: "1.625rem",
      color: "black",
    },
    price: {
      color: "rgba(101, 103, 110, 1)",
      fontSize: "0.875rem",
      fontFamily,
    },
  })
}

const Tier = ({
  classes,
  setTier,
  setAmount,
  name,
  price,
  icon,
  selectedTier,
  children,
  belowAvatar = null,
}) => {
  const handleClick = () => {
    setTier(name)
    if (price) setAmount(price)
  }

  const isSelected = selectedTier === name

  const getClasses = () => {
    const selectionClass = isSelected ? classes.selected : classes.deselected
    return `${selectionClass} ${classes.root}`
  }

  return (
    <Button
      className={getClasses()}
      onClick={handleClick}
      color="secondary"
      fullWidth
      variant="outlined"
      disableRipple
    >
      {isSelected}
      <div className={classes.content}>
        <div>
          <Avatar icon={icon} />
          {belowAvatar}
        </div>
        <div className={classes.body}>
          <Typography className={classes.title}>{name}</Typography>
          {price && <Typography className={classes.price}>${price}</Typography>}
          {children}
        </div>
      </div>
    </Button>
  )
}

export default withStyles(styles)(Tier)
