import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

import Rewards from "./Rewards"
import Text from "./Text"

export class Item extends Component {
  classes = generateClasses.bind(this)({
    name: "item",
    elements: [
      {
        name: "button",
        variants: ["active"],
        elements: [
          {
            name: "header",
            elements: ["name", "price", "emoji"],
          },
          "emoji",
        ],
      },
    ],
  })

  getButtonClasses = () => {
    const { selectedInvestmentLevel, id } = this.props
    const isSelected = selectedInvestmentLevel === id
    if (isSelected) {
      return this.classes.elements.button.variants.active
    }
    return this.classes.elements.button.name
  }

  handleClick = () => {
    const { setInvestmentLevel, id, showModal } = this.props
    setInvestmentLevel(id)
    showModal()
  }

  render() {
    const { label, name, description, emoji, modalId, rewards } = this.props
    const { header } = this.classes.elements.button.elements
    const modalSelector = `#${modalId}`
    return (
      <li className={this.classes.name}>
        <button
          className={this.getButtonClasses()}
          onClick={this.handleClick}
          data-toggle="modal"
          data-target={modalSelector}
        >
          <div className={header.name}>
            <span className={header.elements.price}>${label}+</span>
            <h3 className={header.elements.name}>{name}</h3>
            <p className={header.elements.emoji}>{emoji}</p>
            <Text paragraph>{description}</Text>
          </div>
          <Rewards rewards={rewards} />
        </button>
      </li>
    )
  }
}

export default Item
