import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import BaseSection from "./Section"

export class Partner extends Component {
  classes = generateClasses({
    name: "partner",
  })

  render() {
    const Section = props => (
      <BaseSection {...props} parentClass={this.classes} />
    )
    return (
      <div className={this.classes}>
        <Section title="Why Partner?">
          <p>
            We are looking to create a robust support system that will function
            during thick and thin for the people working to restore and protect
            earth’s most valuable ecosystems.
          </p>
        </Section>
        <Section title="How to Partner?">
          <p>
            If you are an investor or organization, you can pledge to make an
            annual commitment to fund our work and support our operations.{" "}
            <a href="mailto:info@fishcat.org">Write to us.</a>
          </p>
          <p>
            If you are a landowner or farmer living in a distressed ecosystem
            and looking to create a nature reserve, we would be glad to explore
            the potential for a partnership with you.{" "}
            <a href="mailto:info@fishcat.org">Write to us.</a>
          </p>
        </Section>
      </div>
    )
  }
}

export default Partner
