import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Tabs from "../../components/Tabs"
import TabsWithContent from "../../components/TabsWithContent"
import Item from "./TabContent/index"
import Section from "../../components/Section"
import { sectionIds } from "../../constants/sectionIds"

import disneyLogo from "../../images/investors/disney.jpg"
import cedarCoveLogo from "../../images/investors/cedar-cove.jpg"
import projectSurvivalLogo from "../../images/investors/project-survival.png"
import oaklandZooLogo from "../../images/investors/oakland-zoo.png"
import safariWestLogo from "../../images/investors/safari-west.jpg"
import colombusZooLogo from "../../images/investors/columbus-zoo.png"
import paradiseWildlifeParkLogo from "../../images/investors/paradise-wildlife-park.jpg"
import greensboroScienceCenterLogo from "../../images/investors/greensboro-science-center.png"
import wildlifeConservationNetworkLogo from "../../images/investors/wildlife-conservation-network.jpg"
import cincinnatiZooLogo from "../../images/investors/cincinnati-zoo.jpg"
import felineConservationNetworkLogo from "../../images/investors/feline-conservation-foundation.png"
import bigCatSanctuaryLogo from "../../images/investors/big-cat-sanctuary.jpg"
import mbzLogo from "../../images/investors/Mohamed-bin-Zayed-Species-Conservation-Fund-Grant-2019.jpg"
import wildOasisLogo from "../../images/investors/wild-oasis.png"

const cedarCove = {
  name: "Cedar Cove Sanctuary",
  image: cedarCoveLogo,
  url: "https://www.saveoursiberians.org/",
}
const sunkariNarsupalli = {
  name: "The Sunkari-Narsupalli Minds (TSuNaMi) Legacy",
}

const lawrenceThompson = {
  name: "The Lawrence Thompson Living Trust",
}

const melindaCorell = {
  name: "Melinda Correll",
}

const projectSurvival = {
  name: "Project Survival",
  image: projectSurvivalLogo,
  url: "https://cathaven.com/fishing-cat-conservancy/",
}

const safariWest = {
  name: "Safari West",
  image: safariWestLogo,
  url: "https://www.safariwest.com/",
}

const disney = {
  name: "Disney Conservation Fund",
  image: disneyLogo,
  url: "https://thewaltdisneycompany.com/disney-conservation/",
}

const wildlifeConservationNetwork = {
  name: "Wildlife Conservation Network",
  url: "https://wildnet.org/",
  image: wildlifeConservationNetworkLogo,
}

const mohammedConservationFund = {
  name: "Mohammed bin Zayed Species Conservation Fund",
  url: "https://www.speciesconservation.org/",
  image: mbzLogo,
}

const greensBoro = {
  name: "Greensboro Science Center",
  url: "https://www.greensboroscience.org/",
  image: greensboroScienceCenterLogo,
}

const bigCatSanctuary = {
  name: "The Big Cat Sanctuary",
  image: bigCatSanctuaryLogo,
  url: "https://thebigcatsanctuary.org/",
}

const paradiseWildLifePark = {
  name: "Paradise Wildlife Park",
  image: paradiseWildlifeParkLogo,
  url: "https://www.pwpark.com/big-cat-sanctuary/",
}

export class TopInvestors extends Component {
  classes = generateClasses({
    name: "top-investors",
    elements: ["title"],
  })

  investorsByYear = [
    {
      year: 2023,
      items: [
        sunkariNarsupalli,
        {
          name: 'FishCat Legacy Fund'
        },
        {
          name: "Robert Quartermain",
        },
        {
          name: "Suparna Vashisht",
        },
        {
          name: "Dale Anderson"
        },
        {
          name: "Patricia Schwinn"
        },
      ],
    },
    {
      year: 2022,
      items: [
        sunkariNarsupalli,
        {
          name: "Robert Quartermain",
        },
        {
          name: "Suparna Vashisht",
        },
        bigCatSanctuary,
      ],
    },
    {
      year: 2021,
      items: [
        lawrenceThompson,
        melindaCorell,
        sunkariNarsupalli,
        bigCatSanctuary,
        paradiseWildLifePark,
      ],
    },
    {
      year: 2020,
      items: [
        sunkariNarsupalli,
        lawrenceThompson,
        {
          name: "Robert Quartermain",
        },
        melindaCorell,
        bigCatSanctuary,
        paradiseWildLifePark,
      ],
    },
    {
      year: 2019,
      items: [
        sunkariNarsupalli,
        { name: "Julie Raymond + Neil Hunt" },
        melindaCorell,
        {
          name: "Julie Packard",
        },
        lawrenceThompson,
        {
          name: "Buffy Redsecker",
        },
        cedarCove,
        {
          name: "Oakland Zoo",
          image: oaklandZooLogo,
          url: "https://www.oaklandzoo.org/",
        },
        bigCatSanctuary,
        paradiseWildLifePark,
      ],
    },
    {
      year: 2018,
      items: [
        sunkariNarsupalli,
        lawrenceThompson,
        {
          name: "Columbus Zoo",
          image: colombusZooLogo,
          url: "https://www.columbuszoo.org/",
        },
        {
          name: "Clara Lee Arnold",
        },
        {
          name: "Bonnie Kay",
        },
        projectSurvival,
        cedarCove,
      ],
    },
    {
      year: 2017,
      items: [
        sunkariNarsupalli,
        lawrenceThompson,
        greensBoro,
        projectSurvival,
        cedarCove,
        safariWest,
      ],
    },
    {
      year: 2016,
      items: [
        sunkariNarsupalli,
        disney,
        wildlifeConservationNetwork,
        mohammedConservationFund,
        greensBoro,
        safariWest,
      ],
    },
    {
      year: 2015,
      items: [
        sunkariNarsupalli,
        disney,
        wildlifeConservationNetwork,
        mohammedConservationFund,
        {
          name: "Cincinnati Zoo",
          url: "http://cincinnatizoo.org/",
          image: cincinnatiZooLogo,
        },
        {
          name: "Feline Conservation Federation, Wild Oasis + SWCCF",
          url: "https://www.felineconservation.org/",
          image: felineConservationNetworkLogo,
        },
        {
          name: "Wild Oasis",
          url: "http://www.wildoasis.org/",
          image: wildOasisLogo,
        },
      ],
    },
  ]

  renderItem = investors => (
    <Item parentClass={this.classes.name} investors={investors} />
  )

  getSection = ({ year, items }) => ({
    id: year,
    label: year,
    content: this.renderItem(items),
  })

  getSections = () => this.investorsByYear.map(this.getSection)

  state = {
    selectedYear: 2023,
  }

  setSelectedYear = selectedYear => this.setState({ selectedYear })

  render() {
    const sections = this.getSections()
    const { selectedYear } = this.state
    return (
      <Section id={sectionIds.investors} title="Investors and Partners">
        <div className={this.classes.name}>
          <Tabs
            sections={sections}
            parentClass={this.classes.name}
            selectedItem={selectedYear}
            setSelectedItem={this.setSelectedYear}
          />
          <TabsWithContent
            sections={sections}
            parentClass={this.classes.name}
            selectedItem={selectedYear}
          />
        </div>
      </Section>
    )
  }
}

export default TopInvestors
