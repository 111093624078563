import React from "react"

import Text from "../Text"

const Reward = ({ children, isLast }) => {
  return (
    <Text align="left">
      <div  className="rewardsDonation">{children}</div>
    </Text>
  )
}

export default Reward
