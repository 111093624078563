import React from "react"
import { createStyles, withStyles } from "@material-ui/core"
import { reduxForm } from "redux-form"

import InputGrid from "./InputGrid"
import Expiry from "./Expiry"
import Cvc from "./Cvc"
import Name from "./Name"
import { formId } from "../../../../../../constants/donate"
import Email from "./Email"
import CardNumber from "./CardNumber"

const styles = () => {
  return createStyles({
    root: {
      marginTop: "2.75rem",
    },
  })
}

const Form = ({ classes }) => {
  return (
    <form className={classes.root}>
      <Name />
      <Email />
      <CardNumber />
      <InputGrid>
        <Expiry />
        <Cvc />
      </InputGrid>
    </form>
  )
}

const StyledForm = withStyles(styles)(Form)

export default reduxForm({ form: formId })(StyledForm)
