import React, { Component } from "react"
import { extendBaseClass, extendParentClass } from "../../../../utils/classes"
import Item from "./Item/index"

export class Section extends Component {
  baseClass = extendParentClass.bind(this)("section")
  extend = extendBaseClass.bind(this)
  titleClass = this.extend("title")
  descriptionClass = this.extend("description")
  listClass = this.extend("list")

  renderItem = (image, index) => (
    <Item {...image} key={index} parentClass={this.listClass} />
  )

  renderItems = () => {
    const { items } = this.props
    return items.map(this.renderItem)
  }

  render() {
    const { title, description } = this.props
    return (
      <div className={this.baseClass}>
        <h2 className={this.titleClass}>{title}</h2>
        <p className={this.descriptionClass}>{description}</p>
        <ul className={this.listClass}>{this.renderItems()}</ul>
      </div>
    )
  }
}

export default Section
