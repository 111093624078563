import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import ActivateTabButton from "../../components/ActivateTabButton"
import { tabs } from "../../constants/tabs"

export class Buttons extends Component {
  render() {
    const classes = generateClasses.bind(this)({
      name: "buttons",
      elements: [
        {
          name: "button",
          variants: ["video", "investors"],
        },
      ],
    })
    return (
      <div className={classes.name}>
        <a
          href="https://youtu.be/S6rUPnUm-WE"
          className={classes.elements.button.variants.video}
          target="blank"
        >
         Check out our new Video!
        </a>
        <ActivateTabButton
          tab={tabs.ourTeam}
          className={classes.elements.button.variants.investors}
        >
          Map
        </ActivateTabButton>
      </div>
    )
  }
}

export default Buttons
