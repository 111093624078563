import React from "react"

import InvestorWithLogo from "./InvestorWithLogo"
import InvestorWithPlaceholder from "./InvestorWithPlaceholder"

const Investor = ({ classes, ...other }) => {
  const { image } = other
  if (image) {
    return <InvestorWithLogo {...other} />
  }
  return <InvestorWithPlaceholder {...other} />
}

export default Investor
