import React from "react"
import { createStyles, withStyles } from "@material-ui/core"
import { flexBreakpoint } from "./styles"

const styles = theme => {
  return createStyles({
    root: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
  })
}

const InputGrid = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>
}

export default withStyles(styles)(InputGrid)
