import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import { tabs } from "../../constants/tabs"
import Context from "../../Context"
import Tabs from "../../components/Tabs"
import Invest from "./Invest"
import Partner from "./Partner"
import Careers from "./Careers"
import TabsWithContent from "../../components/TabsWithContent"
import { sectionIds } from "../../constants/sectionIds"
import OurTeam from "./OurTeam"

export class WaysYouCanGetInvolved extends Component {
  static contextType = Context

  classes = generateClasses({
    name: "ways-you-can-get-involved",
    elements: [
      {
        name: "container",
        elements: ["title"],
      },
      "anchor",
    ],
  })

  sections = [
    {
      id: tabs.ourTeam,
      label: "Our Team",
      content: <OurTeam />,
    },
    {
      id: tabs.partner,
      label: "Partner",
      content: <Partner />,
    },
    {
      id: tabs.careers,
      label: "Careers",
      content: <Careers />,
    },
  ]

  render() {
    const { selectedSection, setSection } = this.context
    return (
      
      <div className={this.classes.name}>
        <div
          id={sectionIds.waysYouCanGetInvolved}
          className={this.classes.elements.anchor}
        />
        <div className={this.classes.elements.container.name}>
          <div className="waysGetInvTabs">
          <Tabs
            sections={this.sections}
            parentClass={this.classes.elements.container.name}
            selectedItem={selectedSection}
            setSelectedItem={setSection}
          />
          </div>
         
          <TabsWithContent
            sections={this.sections}
            selectedItem={selectedSection}
            parentClass={this.classes.elements.container.name}
          />
        </div>
      </div>
     
    )
  }
}

export default WaysYouCanGetInvolved
