import React, { Component } from "react"
import { generateClasses } from "@supercoder.dev/bem-classes"

export class Content extends Component {
  render() {
    const classes = generateClasses.bind(this)({
      name: "content",
      elements: ["title", "body"],
    })
    const { title, body } = this.props
    return (
      <div className={classes.name}>
        <h2 className={classes.elements.title}>{title}</h2>
        <p className={classes.elements.body}>{body}</p>
      </div>
    )
  }
}

export default Content
