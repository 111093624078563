import React from "react"
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"

import { fields as donateFormFields } from "../../../../../../constants/donate"
import { apiInstance } from "../../../../../../apiInstance"
import ConnectedForm from "./ConnectedForm"

const Form = ({ setPaymentError, amount }) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleError = error => {
    console.error(error)
    setPaymentError(error)
  }

  const getClientSecret = async fields => {
    const {
      data: { secret },
    } = await apiInstance.post("/payments", {
      amount,
      name: fields[donateFormFields.name],
      email: fields[donateFormFields.email],
    })
    return secret
  }

  const validatePayment = error => {
    if (error) {
      return handleError(error)
    } else {
      window.location.href = "/success"
    }
  }

  const confirmCardPayment = async fields => {
    const secret = await getClientSecret(fields)
    const cardElement = elements.getElement(CardNumberElement)
    const { error } = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: fields[donateFormFields.name],
        },
      },
    })
    return validatePayment(error)
  }

  const tryCreatingAPaymentMethod = async fields => {
    if (stripe && elements) {
      await confirmCardPayment(fields)
    }
  }

  const clearErrors = () => {
    setPaymentError(null)
  }

  const handlePaymentFormSubmission = async fields => {
    clearErrors()
    try {
      await tryCreatingAPaymentMethod(fields)
    } catch (error) {
      if (error) handleError(error)
    }
  }

  return <ConnectedForm onSubmit={handlePaymentFormSubmission} />
}

export default Form
