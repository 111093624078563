import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Description extends Component {
  classes = generateClasses.bind(this)({
    name: "description",
    elements: ["title"],
  })

  render() {
    const Heading = ({ children }) => (
      <h4 className={this.classes.elements.title}>{children}</h4>
    )
    return (
      <div className={this.classes.name}>
        <Heading>Are you:</Heading>
        <p>
          Compassionate, open-minded, proactive, honest, responsive, organized,
          and tenacious?
        </p>
        <Heading>Can you:</Heading>
        <p>
          Embrace challenges, commit to continuous learning, demonstrate
          creativity, and show results?
        </p>
        <Heading>Do you:</Heading>
        <p>
          Have a trustworthy, long-term growth mindset, and intend to deliver
          extreme value to the world?
        </p>
        <Heading>Can we count on you?</Heading>
        <p>
          If your answers (to yourself) are a clear yes, and you think being
          part of FishCat can turn into one of the most rewarding experiences of
          your life, we are looking for team players like you.
        </p>
      </div>
    )
  }
}

export default Description
