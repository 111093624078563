import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

const styles = () => {
  const columnGap = "0.98rem"
  return createStyles({
    root: {
      flexBasis: `calc(50% - ${columnGap} / 2)`,
    },
  })
}

const InputColumn = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>
}

export default withStyles(styles)(InputColumn)
