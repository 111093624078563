import React, { Component } from "react"
import Tabs from "../Tabs"
import TabsWithContent from "../TabsWithContent"
import List from "./List"

const baseClass = "positions-section"

export default class PositionsSection extends Component {
  categories = ["fieldwork", "outreach", "operation", "guidance"]

  state = {
    category: this.categories[0],
  }

  setCategory = category => this.setState({ category })

  renderList = category => {
    const { data: positions } = this.props
    const positionsForCategory = positions[category]
    return (
      <List
        positions={positionsForCategory}
        parentClass={baseClass}
        category={category}
      />
    )
  }

  getSection = category => {
    return { id: category, content: this.renderList(category), label: category }
  }

  getSections = () => {
    return this.categories.map(this.getSection)
  }

  render() {
    const sections = this.getSections()
    const { category } = this.state
    return (
      <div>
        <Tabs
          parentClass={baseClass}
          sections={sections}
          setSelectedItem={this.setCategory}
          selectedItem={category}
        />
        <TabsWithContent
          sections={sections}
          parentClass={baseClass}
          selectedItem={category}
        />
      </div>
    )
  }
}
