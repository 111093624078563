import React from "react"
import {
  createStyles,
  withStyles,
  InputAdornment,
  Typography,
} from "@material-ui/core"

import Tier from "./Tier"
import TextField from "../Form/TextField"

const styles = () => {
  return createStyles({
    root: {
      position: "relative",
      padding: "1.5625rem 1.0625rem",
      borderWidth: "0.0625rem",
      borderStyle: "solid",
      justifyContent: "flex-start",
      textAlign: "left",
    },
    input: {
      width: "100%",
    },
    label: {
      display: "none",
    },
    error: {
      fontSize: "0.875rem",
      marginTop: "0.5rem",
    },
    currency: {
      marginTop: "0.25rem",
    },
  })
}

const CustomAmount = ({
  classes,
  setAmount,
  setTier,
  selectedTier,
  amount,
  isAmountValid,
  ...other
}) => {
  const name = "Choose Amount to Donate"

  const handleChange = event => {
    const isSelected = name === selectedTier
    if (!isSelected) setTier(name)
    setAmount(event.target.value)
  }

  const sanitizeAmount = () => {
    if (!amount || Number.isNaN(amount)) {
      return setAmount(0)
    }
    const amountAsInteger = parseInt(amount)
    return setAmount(amountAsInteger)
  }

  const getError = () => {
    if (isAmountValid) return null
    return "Please enter an amount greater than $1."
  }

  const handleBlur = () => {
    sanitizeAmount()
  }

  const id = "setAmount"
  const error = getError()

  return (
    <Tier
      name={name}
      icon="❤️"
      setTier={setTier}
      selectedTier={selectedTier}
      belowAvatar={
        <Typography
          color="textPrimary"
          align="center"
          className={classes.currency}
        >
          USD
        </Typography>
      }
      {...other}
    >
      <div className="USD">
        <TextField
          classes={{ input: classes.input }}
          inputProps={{
            type: "number",
            variant: "outlined",
            id,
            onBlur: handleBlur,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          input={{ value: amount, onChange: handleChange }}
          meta={{ error }}
        />
      </div>
    </Tier>
  )
}

export default withStyles(styles)(CustomAmount)
