import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import {
  extend,
  getClassExtender,
  getClassWithVariant,
  getClassWithVariants,
} from "../../utils/classes"
import { getImageVariant } from "../../utils/image"

export default class Section extends Component {
  classes = generateClasses({
    name: "section",
    elements: [
      "anchor",
      {
        name: "body-and-image",
        elements: [
          {
            name: "body",
            elements: [
              "button",
              "title",
              {
                name: "content",
                elements: ["button"],
              },
            ],
          },
          {
            name: "image-with-credit",
          },
          {
            name: "image",
          },
        ],
      },
    ],
  })

  getImageExistsVariant = () => {
    const { Image, Images, ImageWithCredit, imageVariant = null } = this.props
    const hasImage = Image || Images || ImageWithCredit
    return hasImage ? imageVariant : "no-image"
  }

  getImageVariant = getImageVariant.bind(this)

  getSectionVariants = (baseClass, Image, Images) => {
    const imageVariant = this.getImageExistsVariant(Image, Images)
    return getClassWithVariant(imageVariant, baseClass)
  }

  getBodyVariants = (bodyClass, reversedVariant) => {
    const imageVariant = this.getImageExistsVariant()
    const variants = [imageVariant, reversedVariant]
    return getClassWithVariants(variants, bodyClass)
  }

  render() {
    const {
      title,
      children,
      imageVariant,
      Button = null,
      belowSection = null,
      isReversed = false,
      columnContent = null,
      id = null,
      Image = null,
      Images = null,
      ImageWithCredit = null,
    } = this.props

    const baseClass = "section"
    const baseClassVariant = this.getSectionVariants(baseClass, Image, Images)

    const extendBaseClass = getClassExtender(baseClass)

    const bottomClass = extendBaseClass("bottom")
    const bodyAndImageClass = extendBaseClass("body-and-image")
    const extendBodyAndImage = getClassExtender(bodyAndImageClass)

    const imageClass = extendBodyAndImage("image")
    const imageWithCreditClass = extendBodyAndImage("image-with-credit")
    const anchorClass = extendBaseClass("anchor")

    const variant = isReversed ? "reversed" : "normal"
    const getReversedVariant = block => getClassWithVariant(variant, block)

    const bodyClass = extendBodyAndImage("body")
    const bodyReversedVariantClass = getReversedVariant(bodyClass)
    const bodyVariants = this.getBodyVariants(
      bodyClass,
      bodyReversedVariantClass
    )
    const imageVariantClass = this.getImageVariant(imageClass)
    const contentClass = extend(bodyClass, "content")
    const contentBottomClass = extend(contentClass, "bottom")
    const titleClass = extend(bodyClass, "title")

    return (
      <div className={baseClassVariant}>
        <div id={id} className={anchorClass} />
        <div className={bodyAndImageClass}>
          {Image && <Image className={imageVariantClass} />}
          {Images && (
            <Images
              parentClass={bodyAndImageClass}
              imageVariant={imageVariant}
            />
          )}
          {ImageWithCredit && (
            <ImageWithCredit className={imageWithCreditClass} />
          )}
          <div className={bodyVariants}>
            <h2 className={titleClass}>{title}</h2>
            <div className={contentClass}>
              {children}
              {Button && (
                <Button
                  className={
                    this.classes.elements["body-and-image"].elements.body
                      .elements.content.elements.button
                  }
                />
              )}
              <div className={contentBottomClass}>{belowSection}</div>
            </div>
          </div>
          {columnContent}
        </div>
        <div className={bottomClass}>{belowSection}</div>
      </div>
    )
  }
}
