import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { Component } from "react"
import Section from "../../components/Section/index.js"
import { sectionIds } from "../../constants/sectionIds"

const Button = props => (
  <a href="https://teespring.com/stores/fishcat" {...props}>
    Buy ReMerch
  </a>
)

function Image(props) {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "merch" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return image && <GatsbyImage fluid={image.childImageSharp.fluid} {...props} />
}

export class Merchandise extends Component {
  render() {
    return (
      <Section
        title="Wear ReShirt"
        subtitle="Reforest Earth"
        Image={Image}
        Button={Button}
        id={sectionIds.merchandise}
      >
        <p>
          Invest with our ReMerch — concept reforestation merchandise where each
          campaign is unique and links to the partner lands we are working to
          restore and turn into nature reserves.
        </p>

        <p>Scan the merch and see how it works!</p>
      </Section>
    )
  }
}

export default Merchandise
