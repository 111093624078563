import React from "react"
import { createStyles, withStyles, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

const styles = () => {
  const size = "1.5rem"
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      width: size,
      height: size,
    },
  })
}

const Nav = ({ classes, hideModal }) => {
  return (
    <div className={classes.root}>
      <IconButton onClick={hideModal} className={classes.button}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default withStyles(styles)(Nav)
