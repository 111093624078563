import React from "react"
import { Button, Typography, createStyles, withStyles } from "@material-ui/core"
import Tier from "./Tier"
import CustomAmount from "./CustomAmount"
import { tiers } from "../../../../../../constants/investmentLevels"


const SelectTier = ({
  setAmount,
  amount,
  selectedTier,
  setTier,
  isAmountValid,
}) => {
  const renderTier = tier => (
  
    <Tier
      {...tier}
      key={tier.name}
      setAmount={setAmount}
      setTier={setTier}
      selectedTier={selectedTier}
    />
    
  )

  const renderedTiers = tiers.map(renderTier)
  //removes first item from drop down payment
  renderedTiers.shift() 


  return (
    <div className="amountWarpper">
       <CustomAmount
         fullWidth
        setAmount={setAmount}
        setTier={setTier}
        selectedTier={selectedTier}
        amount={amount}
        isAmountValid={isAmountValid}
      />     
    </div>
  )
}

export default SelectTier
