import React from "react"
import { createStyles, withStyles, Typography } from "@material-ui/core"
import { fontFamily } from "../styles"

const styles = () => {
  return createStyles({
    root: {
      marginBottom: "1.0625rem",
      width: "100%",
      boxSizing: "border-box",
    },
    inputContainer: {
      border: "0.0625rem solid rgba(225, 228, 235, 1)",
      borderRadius: "0.375rem",
    },
    label: {
      fontSize: "0.875rem",
      fontWeight: 500,
      marginBottom: "0.375rem",
      fontFamily,
    },
  })
}

const InputGroup = ({ classes, children, label, Container }) => {
  return (
    <label className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Container>{children}</Container>
    </label>
  )
}

export default withStyles(styles)(InputGroup)
