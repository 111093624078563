import React from "react"
import { createStyles, withStyles, Typography } from "@material-ui/core"

import BaseCard from "./BaseCard"
import fishcatLogo from "../../../../images/favicon_whitebkg_large.png"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    placeholder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1.0625rem",
    },
    placeholderText: {
      textTransform: "uppercase",
      color: "#A9ABBD",
      fontSize: "0.625rem",
      fontWeight: 500,
    },
    image: {
      maxHeight: "6rem",
      marginBottom: 0,
    },
    name: {
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      fontSize: "0.875rem",
      marginTop: "auto",
    },
  })
}

const InvestorWithLogo = ({ classes, image, name, ...other }) => {
  return (
    <BaseCard classes={{ root: classes.root }} {...other}>
      <div className={classes.placeholder}>
        <img src={fishcatLogo} alt={name} className={classes.image} />
        <Typography className={classes.placeholderText}>
          FishCat Investor
        </Typography>
      </div>
      <Typography className={classes.name}>{name}</Typography>
    </BaseCard>
  )
}

export default withStyles(styles)(InvestorWithLogo)
