import React, { Component } from "react"
import { generateClasses } from "@supercoder.dev/bem-classes"
import {
  Dialog,
  withStyles,
  createStyles,
  DialogContent,
} from "@material-ui/core"

import Form from "./Form"
import PaymentError from "./PaymentError"
import PaymentDetails from "./headers/PaymentDetails"
import Nav from "./Nav"
import Footer from "./Footer"
import SelectTier from "./SelectTier"
import { tiers } from "../../../../../constants/investmentLevels"

const styles = theme => {
  return createStyles({
    container: {
      paddingTop: "1.3125rem",
    },
    paper: {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "100%",
      },
    },
  })
}

export class ChooseAmount extends Component {
  defaultTier = tiers[0]

  state = {
    amount: this.defaultTier.price,
    paymentError: null,
    fillOutPaymentDetails: false,
    tier: this.defaultTier.name,
  }

  setAmount = amount => this.setState(prevState => ({ ...prevState, amount }))

  classes = generateClasses.bind(this)({
    name: "choose-amount",
    elements: [
      {
        name: "input-group",
        elements: [
          {
            name: "input-container",
            elements: [
              "input",
              { name: "currency-symbol", elements: ["text"] },
            ],
          },
          "error",
        ],
      },
    ],
  })

  setPaymentError = paymentError =>
    this.setState(prevState => ({ ...prevState, paymentError }))

  setFillOutPaymentDetails = fillOutPaymentDetails =>
    this.setState(prevState => ({
      ...prevState,
      fillOutPaymentDetails,
    }))

  handleClose = () => {
    const { hideModal } = this.props
    this.setFillOutPaymentDetails(false)
    hideModal()
  }

  renderModalContent = () => {
    const { fillOutPaymentDetails, amount, tier } = this.state
    if (fillOutPaymentDetails) {
      return <Form setPaymentError={this.setPaymentError} amount={amount} />
    }
    return (
      <SelectTier
        setAmount={this.setAmount}
        amount={amount}
        selectedTier={tier}
        setTier={this.setTier}
        isAmountValid={this.validateAmount()}
      />
    )
  }

  setTier = tier =>
    this.setState(prevState => ({
      ...prevState,
      tier,
    }))

  validateAmount = () => {
    const { amount } = this.state
    return amount >= 1
  }

  render() {
    const { paymentError, fillOutPaymentDetails, amount } = this.state
    const { showModal, classes } = this.props
    return (
      <Dialog
        maxWidth="sm"
        open={showModal}
        onClose={this.handleClose}
        scroll="paper"
        classes={{ paper: classes.paper }}
      >
        <DialogContent className={classes.container}>
          <Nav hideModal={this.handleClose} />
          <PaymentDetails />
          {paymentError && <PaymentError parentClass={this.classes.name} />}
          {this.renderModalContent()}
        </DialogContent>
        <Footer
          setFillOutPaymentDetails={this.setFillOutPaymentDetails}
          fillOutPaymentDetails={fillOutPaymentDetails}
          amount={amount}
          isAmountValid={this.validateAmount()}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(ChooseAmount)
