import React from "react"
import {
  Button as BaseButton,
  createStyles,
  withStyles,
} from "@material-ui/core"

const styles = theme => {
  return createStyles({
    button: {
      padding: "0.75rem",
      borderRadius: "0.3125rem",
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      "&:hover": {
        color: theme.palette.secondary.contrastText,
      },
    },
  })
}

const Button = ({ classes, ...other }) => {
  return (
    <BaseButton
      color="secondary"
      variant="contained"
      className={classes.button}
      fullWidth
      {...other}
    />
  )
}

export default withStyles(styles)(Button)
