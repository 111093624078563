import React from "react"
import { CardNumberElement as BaseCardNumberElement } from "@stripe/react-stripe-js"

import InputGroup from "./GroupWithPadding"
import withStripeOptions from "./withStripeOptions"

const CardNumberElement = withStripeOptions(BaseCardNumberElement)

const CardNumber = () => (
  <InputGroup label="Card number">
    <CardNumberElement />
  </InputGroup>
)

export default CardNumber
