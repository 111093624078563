import React from "react"

import InputGroup from "./InputGroup"
import Container from "./InputGroup/Container"

const GroupWithoutPadding = ({ classes, ...other }) => {
  return <InputGroup Container={Container} {...other} />
}

export default GroupWithoutPadding
