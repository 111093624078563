import React, { Component } from "react"

export class ViewReserve extends Component {
  render() {
    return (
      <a {...this.props} href="https://goo.gl/maps/kxchE1KR3uNgXBVU6">
        Nature Reserves
      </a>
    )
  }
}

export default ViewReserve
