import React, { Component } from "react"
import { openMail } from "../utils/mail"

export class EmailButton extends Component {
  render() {
    return (
      <button className="email-button" onClick={openMail}>
        email us
      </button>
    )
  }
}

export default EmailButton
