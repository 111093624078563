import React from "react"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Section from "../components/Section"
import BaseImageWithCredit from "../components/ImageWithCredit"
import { sectionIds } from "../constants/sectionIds"
import ActivateTabButton from "../components/ActivateTabButton"
import { tabs } from "../constants/tabs"

function Image(props) {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "2020_EarthCamp_BCS_IND_FishCat_AshwinNaidu" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return image && <GatsbyImage fluid={image.childImageSharp.fluid} {...props} />
}

const ImageWithCredit = props => (
  <BaseImageWithCredit
    Image={Image}
    credit="Appa Swamy and his Father with James from the Big Cat Sanctuary"
    creditVariant="top-left"
    {...props}
  />
)

const Button = props => (
    <a style={{border: '1px solid #ffd86d', borderRadius: '4px', color: "#212529", backgroundColor: "#ffd86d", borderColor: "#ffd86d", padding: "0.375rem 0.75rem", lineHeight: "1.5", fontSize: ".875rem", width: "100%", marginTop: "1.875rem", fontWeight: '700', textDecoration: "none"}} href="https://buy.stripe.com/14k02u2gL2CQ6Yg001" id="subscribe">Subscribe to FishCat</a>

)

export default function RelationshipsForLife() {
  return (
    <Section
      title="Relationships for Life"
      Image={ImageWithCredit}
      id={sectionIds.relationshipsForLife}
      Button={Button}
      isReversed
    >
      <p>
        At FishCat, we’re a company of conservationists working to create sustainable jobs for conservationists.
      </p>
      <p>
        If you are unable to visit our Nature Reserves, you can still support our work by subscribing to FishCat with as little as $10 per month.
      </p>
    </Section>
  )
}
