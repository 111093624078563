import React from "react"
import Section from "../Section"
import paypal from "./paypal.png"
import grapevine from "./grapevine.png"
import heart from "./heart.png"

export default function Links({ parentClass }) {
  return (
    <Section
      items={[
        {
          src: paypal,
          alt: "Paypal Giving Fund",
          id: "paypal",
          note: "Preferred option",
          href: "https://www.paypal.com/us/fundraiser/charity/1910127",
        },     
        {
          src: heart,
          note: "Support + follow",
          alt: "Heart",
          href: "https://www.facebook.com/fishingcatconservancy/",
          id: "heart",
        },
        {
          src: grapevine,
          alt: "Grapevine",
          note: "Recurring investment",
          href: "https://www.grapevine.org/donate/fund/AGsrW",
          id: "grapevine",
        },
      ]}
      parentClass={parentClass}
    ></Section>
  )
}
