import React, { Component } from "react"
import TeamMap from "../../components/TeamMap"

export class OurTeam extends Component {
  render() {
    return <TeamMap />
  }
}

export default OurTeam
