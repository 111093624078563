import React, { Component } from "react"
export class TeamMap extends Component {
  render() {
    return (
      <div>
        <iframe
        src="https://www.google.com/maps/d/embed?mid=1GQVSEkQXPBOi3wwIURpe_yREccz1OEWL"
        width="640"
        height="480"
        title="team map"
        ></iframe>
      </div>
    )
  }
}

export default TeamMap
