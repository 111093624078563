import React from "react"
import Media from "./Media"
import Merchandise from "./Merchandise"
import Camps from "./Camps"
import Description from "./Description/index"
import Cat from "./Cat"
import LandPartnerships from "./LandPartnerships/index"
import OurGoal from "./OurGoal"
import RelationshipsForLife from "./RelationshipsForLife"
import WaysYouCanGetInvolved from "./WaysYouCanGetInvolved"
import TopInvestors from "./TopInvestors"
import Financials from "./Financials"

export default function Home() {
  // if (!process.env.GATSBY_STRIPE_KEY) {
  //   throw new Error("Please define a STRIPE_KEY in the environment variables.")
  // }

  return (
    <div>
      <Description />
      <OurGoal />
      <LandPartnerships />
      <RelationshipsForLife />
      <Camps />
      <WaysYouCanGetInvolved />
      <Cat />
      <Merchandise />
      <TopInvestors />
      <Financials />
      <Media />
    </div>
  )
}
