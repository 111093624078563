import React, { Component } from "react"
import Image from "gatsby-image"
import List from "./List"

export class ImageGroup extends Component {
  static getComponentFromImages(images) {
    return props => <ImageGroup {...props} images={images} />
  }

  getImageComponent = image => {
    return props => {
      if (!image.childImageSharp) return null
      return <Image fluid={image.childImageSharp.fluid} {...props} />
    }
  }

  getItems = () => {
    const { images } = this.props
    return images.map(this.getImageComponent)
  }

  render() {
    const { parentClass, imageVariant } = this.props
    const items = this.getItems()
    return (
      <List
        parentClass={parentClass}
        imageVariant={imageVariant}
        items={items}
      ></List>
    )
  }
}

export default ImageGroup
