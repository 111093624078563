import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Section extends Component {
  render() {
    const classes = generateClasses.bind(this)({
      name: "section",
      elements: ["title"],
    })
    const { title, children } = this.props
    return (
      <div className={classes.name}>
        <h3 className={classes.elements.title}>{title}</h3>
        {children}
      </div>
    )
  }
}

export default Section
