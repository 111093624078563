import React, { Component } from "react"
import Image from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import { generateClasses } from "@supercoder.dev/bem-classes"

export class Seal extends Component {
  className = generateClasses.bind(this)({ name: "seal" })

  render() {
    const { file } = this.props.data
    if (file) {
      return (
        <a href="https://www.guidestar.org/profile/81-0894559">
          <Image
            fluid={file.childImageSharp.fluid}
            alt="Guidestar Platinum Seal of Transparency"
            className={this.className}
          />
        </a>
      )
    }
    return null
  }
}

class SealWithQuery extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            file(name: { eq: "seal" }) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => <Seal data={data} {...this.props} />}
      />
    )
  }
}

export default SealWithQuery
