import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import { extendBaseClass, getClassExtender } from "../../utils/classes"
import Header from "./Header"
import Info from "./Info"

export default class Description extends Component {
  baseClass = "description"

  extend = extendBaseClass.bind(this)

  bodyClass = this.extend("body")
  anchorClass = this.extend("anchor")

  extendBodyClass = getClassExtender(this.bodyClass)

  render() {
    const classes = generateClasses({
      name: "description",
      elements: [
        "anchor",
        "body",
        {
          name: "credit",
          variants: ["bottom-left"],
        },
      ],
    })
    return (
      <div className={classes.name}>
        <div id="description" className={classes.elements.anchor} />
        <div className={classes.elements.body}>
          <Header parentClass={this.bodyClass} />
          <Info parentClass={this.bodyClass} />
        </div>
        <span className={classes.elements.credit.variants["bottom-left"]}>
          Photo: Srikanth Mannepuri for FishCat.org
        </span>
      </div>
    )
  }
}
