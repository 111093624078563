export const fontFamily = "Montserrat, sans-serif"

export const containerPadding = "0.5938rem 1rem"

export const placeholderStyles = {
  color: "rgba(169, 171, 189, 1)",
}

export const baseStyles = {
  fontSize: "14px",
  fontFamily,
}
