import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

import BaseCard from "./BaseCard"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    image: {
      maxHeight: "7.5rem",
      display: "block",
      margin: "0 auto",
    },
  })
}

const InvestorWithLogo = ({ classes, image, name, ...other }) => {
  return (
    <BaseCard classes={{ root: classes.root }} {...other}>
      <img src={image} alt={name} className={classes.image} />
    </BaseCard>
  )
}

export default withStyles(styles)(InvestorWithLogo)
