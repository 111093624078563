import React, { Component } from "react"
import { sectionIds } from "../../constants/sectionIds"
import { extend, extendParentClass } from "../../utils/classes"
import { getScrollToId } from "../../utils/scroll"
import arrowDown from "./arrow-down.svg"

export class LearnMore extends Component {
  baseClass = extendParentClass.bind(this)("button")
  buttonIconClass = extend(this.baseClass, "icon")

  handleClick = getScrollToId(sectionIds.contribution)

  render() {
    return (
      <button className={this.baseClass} onClick={this.handleClick}>
        Here's How
        <img
          src={arrowDown}
          alt="Learn more"
          className={this.buttonIconClass}
        />
      </button>
    )
  }
}

export default LearnMore
