import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

import logo from "../../../../../../../images/favicon_whitebkg_large.png"

const styles = () => {
  const size = "4.375rem"
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "1.5625rem",
    },
    logo: {
      width: size,
      height: size,
      borderRadius: "50%",
    },
  })
}

const Logo = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img src={logo} alt="FishCat logo" className={classes.logo} />
    </div>
  )
}

export default withStyles(styles)(Logo)
