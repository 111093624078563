import React from "react"
import Section from "../../components/Section"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { sectionIds } from "../../constants/sectionIds"
import BaseImageWithCredit from "../../components/ImageWithCredit"
import ViewPartnerLands from "../../components/ViewPartnerLands"

function Image(props) {
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        name: { eq: "2019_HiRes_Ramakrishna_MangroveCrab_AshwinNaidu" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return image && <GatsbyImage fluid={image.childImageSharp.fluid} {...props} />
}

const ImageWithCredit = props => (
  <BaseImageWithCredit
    Image={Image}
    credit="Ramakrishna — one of our land and mangrove crab farmer partners"
    creditVariant="top-left"
    {...props}
  />
)

export default function LandPartnerships() {
  return (
    <Section
      title="Land Partnerships"
      Image={ImageWithCredit}
      id={sectionIds.landPartnerships}
      Button={ViewPartnerLands}
    >
      <p>
        We’ve created partnerships and established Nature Reserves located in Sri Lanka, India, and the United States
      </p>
      <p>
        Below is a link that shows you the locations of these Nature Reserves. If you are interested in becoming our land partner and establishing your land as a FishCat Nature Reserve + Your Name, please email us.
      </p>
    </Section>
  )
}
