import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Item from "./Item"
import { parentClass } from "../../../types/parentClass"

import donate from "./icons/donate.svg"
import ecoFriendly from "./icons/eco-friendly.svg"
import money from "./icons/money.svg"
import partners from "./icons/partner.svg"
import { sectionIds } from "../../../constants/sectionIds"

export class Info extends Component {
  static propTypes = {
    parentClass: parentClass.isRequired,
  }

  getClassName = () =>
    generateClasses.bind(this)({
      name: "info",
    })

  renderItem = (itemData, index) => {
    return <Item {...itemData} key={index} parentClass={this.getClassName()} />
  }

  renderItems = () => {
    const items = [
      {
        title: "Donate",
        iconId: "contribution",
        body:
          "It all starts with your interest and decision to experience conservation with FishCat as an explorer and donor",
        icon: donate,
        iconAlt: "donate",
        id: sectionIds.contribution,
      },
      {
        title: "Experience",
        iconId: "operations",
        body:
          "Visit our Nature Reserves — we partner with landowners and conservationists who protect land as habitat for endangered species",
        icon: partners,
        iconAlt: "operations",
      },
      {
        title: "Incentives",
        iconId: "incentives",
        body:
          "Your repeat visits and donations incentivize conservationists and landowners to protect and restore ecosystems",
        icon: money,
        iconAlt: "incentives",
      },
      {
        title: "Impact",
        iconId: "impact",
        body:
          "Together, we create conservation-based livelihoods that ensure ecological and economic sustainability",
        icon: ecoFriendly,
        iconAlt: "impact",
      },
    ]
    return items.map(this.renderItem)
  }

  render() {
    const items = this.renderItems()
    return <div className={this.getClassName()}>{items}</div>
  }
}

export default Info
