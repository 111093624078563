import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

import InputGroup from "./InputGroup"
import { Container, getStyles } from "./InputGroup/Container"
import { containerPadding } from "./styles"

const styles = () => {
  const defaultStyles = getStyles()
  return createStyles({
    root: {
      ...defaultStyles.root,
      padding: containerPadding,
    },
  })
}

const ContainerWithPadding = withStyles(styles)(Container)

const GroupWithPadding = ({ classes, ...other }) => {
  return <InputGroup Container={ContainerWithPadding} {...other} />
}

export default withStyles(styles)(GroupWithPadding)
