import React, { Component } from "react"
import { extend, extendParentClass } from "../../../utils/classes"

export class Item extends Component {
  baseClass = extendParentClass.bind(this)("item")
  linkClass = extend(this.baseClass, "link")
  imageClass = extend(this.linkClass, "image")

  render() {
    const { src, alt, href } = this.props
    return (
      <li className={this.baseClass}>
        <a href={href} className={this.linkClass}>
          <img src={src} alt={alt} className={this.imageClass} />
        </a>
      </li>
    )
  }
}

export default Item
