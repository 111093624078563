import React, { useState } from "react"

export default function ToggleMap({ children, map, ...other }) {
  const [showMap, setShowMap] = useState(false)

  const toggleMap = () => setShowMap(!showMap)
  const label = showMap ? "Hide" : children
  return (
    <>
      <button {...other} onClick={toggleMap}>
        {label}
      </button>
      {showMap && map}
    </>
  )
}
