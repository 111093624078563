import React from "react"
import ToggleMap from "../ToggleMap"
import Map from "./Map"

export default function ViewPartnerLands(props) {
  return (
    <ToggleMap map={<Map />} {...props}>
      Partner Lands
    </ToggleMap>
  )
}
