import React from "react"
import { CardCvcElement as BaseCardCvcElement } from "@stripe/react-stripe-js"

import InputColumn from "./InputGrid/InputColumn"
import InputGroup from "./GroupWithPadding"
import withStripeOptions from "./withStripeOptions"

const CardCvcElement = withStripeOptions(BaseCardCvcElement)

const Expiry = () => {
  return (
    <InputColumn>
      <InputGroup label="CVC">
        <CardCvcElement />
      </InputGroup>
    </InputColumn>
  )
}

export default Expiry
