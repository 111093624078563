import React, { Component } from "react"
import { extendParentClass, extend } from "../../utils/classes"
import { getImageVariant } from "../../utils/image"

export class List extends Component {
  baseClass = extendParentClass.bind(this)("image-group")
  itemClass = extend(this.baseClass, "item")

  getBaseClassVariant = () => {
    return getImageVariant.bind(this)(this.baseClass)
  }

  renderItem = (Item, index) => {
    return <Item key={index} className={this.itemClass} />
  }

  renderItems = () => {
    const { items } = this.props
    return items.map(this.renderItem)
  }

  render() {
    return (
      <div className={this.getBaseClassVariant()}>{this.renderItems()}</div>
    )
  }
}

export default List
