import React from "react"
import Arrow from "@material-ui/icons/ArrowForward"

import Button from "./Button"

const GoToBilling = ({ setFillOutPaymentDetails, isAmountValid }) => {
  const handleClick = () => setFillOutPaymentDetails(true)
  return (
    <Button onClick={handleClick} endIcon={<Arrow />} disabled={!isAmountValid}>
      Billing Details
    </Button>
  )
}

export default GoToBilling
