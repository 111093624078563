import React from "react"
import { extend, getClassExtender } from "../../../utils/classes"
import Body from "../../JobDescription"
import Item from "./Item"

const renderPosition = parentClass => (
  {
    node: {
      frontmatter: { name, isOpen },
      fields: { slug },
    },
  },
  index
) => (
  <Item
    name={name}
    path={slug}
    isOpen={isOpen}
    key={index}
    parentClass={parentClass}
  />
)

const renderPositions = (positions, parentClass) =>
  positions.map(renderPosition(parentClass))

export default function List({ positions: { edges }, parentClass }) {
  const baseClass = extend(parentClass, "list")
  const extendBaseClass = getClassExtender(baseClass)
  const positionsClass = extendBaseClass("positions")
  return (
    <li className={baseClass}>
      <ul className={positionsClass}>
        {renderPositions(edges, positionsClass)}
      </ul>
      <Body />
    </li>
  )
}
