import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Line extends Component {
  classes = generateClasses.bind(this)({
    name: "line",
    elements: ["segment"],
  })

  segmentAmount = 3

  renderSegment = index => (
    <div key={index} className={this.classes.elements.segment} />
  )

  renderSegments = () => {
    let segments = []
    for (
      let segmentIndex = 0;
      segmentIndex < this.segmentAmount;
      segmentIndex++
    ) {
      const segment = this.renderSegment(segmentIndex)
      segments = [...segments, segment]
    }
    return segments
  }

  render() {
    const segments = this.renderSegments()
    return <div className={this.classes.name}>{segments}</div>
  }
}

export default Line
