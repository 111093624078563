export const investmentLevels = {
  hundredPlus: "conservationPartner",
  thousandPlus: "carbonSinkCreator",
  tenThousandPlus: "earthCampAdopter",
  oneHundredThousandPlus: "oneHundredThousandPlus",
}

export const tiers = [
  {
    label: "1",
    price: 1,
    name: "FishCat Investor",
    description: "Help us build trust with landowners and farmers",
    rewards: ["📧 Our Quarterly Letters with Field Updates"],
  
  },
  {
    label: "100",
    price: 100,
    name: "Earth Camper",
    id: investmentLevels.hundredPlus,
    description: "Help us setup land partner incentive contracts",
    rewards: [
      "📧 Quarterly Letters",
      "🧑‍ Personal Connect by one of our Team Members",
    ],
  
    investmentLevelId: 1,
  },
  {
    label: "1K",
    price: 1000,
    name: " Conservation Partner",
    id: investmentLevels.thousandPlus,
    description: "Help our partners lead forest-based livelihoods",
    rewards: [
      "📧 Quarterly Letters",
      "🧑 ‍Personal Connect",
      // "🏕 An Invitation to one of our Expeditions",
    ],

    investmentLevelId: 2,
  },
  {
    label: "10K",
    price: 10_000,
    name: "Reserve Adopter",
    id: investmentLevels.tenThousandPlus,
    description: "Help our teams restore 10-acre+ ecosystems",
    rewards: [
      "📧 Quarterly Letters",
      "🧑‍ Personal Connect",
      // "🏕 Expedition Invitation",
      "",
      "🌳 Lifetime Invite encoded with the location of your adopted Nature Reserve",
    ],

    investmentLevelId: 3,
  },
]

export default investmentLevels
