import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Icon from "./Icon"
import Content from "./Content"
import { parentClass } from "../../../../types/parentClass"
import Line from "./Line"

export class Item extends Component {
  static propTypes = {
    parentClass: parentClass.isRequired,
  }

  render() {
    const { body, icon, iconAlt, title, iconId, id = null } = this.props
    const classes = generateClasses.bind(this)({
      name: "item",
      elements: ["anchor", "icon-and-line"],
    })
    return (
      <div className={classes.name}>
        {id && <div className={classes.elements.anchor} id={id} />}
        <div className={classes.elements["icon-and-line"]}>
          <Line parentClass={classes.elements["icon-and-line"]} />
          <Icon
            parentClass={classes.elements["icon-and-line"]}
            icon={icon}
            iconAlt={iconAlt}
            id={iconId}
          />
          <Line parentClass={classes.elements["icon-and-line"]} />
        </div>
        <Content parentClass={classes.name} title={title} body={body} />
      </div>
    )
  }
}

export default Item
