import { createStyles, withStyles, Typography } from "@material-ui/core"

const styles = () => {
  return createStyles({
    root: {
      fontSize: "0.875rem",
      color: "rgba(0, 0, 0, 0.85)",
      letterSpacing: 0,
      lineHeight: "1.25rem",
    },
  })
}

export default withStyles(styles)(Typography)
