import React, { Component } from "react"
import Links from "./Links"

export class Primary extends Component {
  render() {
    return (
      <div>
        <p>
          Your investment is a philanthropic contribution to further our
          mission, and we would love to keep you updated on our progress.{" "}
        </p>
        <p>Select how you would like to Donate:</p>
        <Links {...this.props} />
      </div>
    )
  }
}

export default Primary
