import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

const getCardWidthStyles = columns => {
  const gap = "2.125rem"
  const width = `calc((100% / ${columns}) - ${gap} / 2)`
  return {
    width,
    flexBasis: width,
    marginRight: "auto",
  }
}

const styles = theme => {
  const cardVerticalMargin = "3.25rem"
  return createStyles({
    root: {
      boxShadow:
        "-1px 1px 2px rgba(67, 70, 74, 0.0001), -2px 2px 5px rgba(67, 86, 100, 0.123689)",
      borderRadius: "0.5rem",
      background: "white",
      padding: "1.0625rem 0.6875rem",
      textDecoration: "none",
      color: "black",
      "&:hover": {
        textDecoration: "none",
      },
      "& + &": {
        marginTop: cardVerticalMargin,
        [theme.breakpoints.up("sm")]: {
          marginTop: 0,
        },
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: cardVerticalMargin,
        ...getCardWidthStyles(2),
      },
      [theme.breakpoints.up("md")]: {
        ...getCardWidthStyles(3),
      },
    },
  })
}

const Investor = ({ classes, url, children }) => {
  return (
    <a className={classes.root} href={url}>
      {children}
    </a>
  )
}

export default withStyles(styles)(Investor)
