import React, { Component } from "react"

export class Map extends Component {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1xINn9gobbX1cBWlMMsjyDOp0py9YqnXj"
        width="640"
        height="480"
        title="Partners Map"
      ></iframe>
    )
  }
}

export default Map
