import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import { extendParentClass } from "../../../../../utils/classes"

export class Content extends Component {
  extendParentClass = extendParentClass.bind(this)
  imageClass = this.extendParentClass("image")
  anchorClass = this.extendParentClass("anchor")

  classes = generateClasses({
    name: this.props.parentClass,
    elements: [
      {
        name: "image",
        variants: [this.props.id],
      },
      "anchor",
    ],
  })

  renderAnchor = () => {
    const { id = null } = this.props
    return id && <div id={id} className={this.classes.elements.anchor} />
  }

  getImageClass = () => {
    const { id } = this.props
    return this.classes.elements.image.variants[id]
  }

  render() {
    const { src, alt } = this.props
    return (
      <>
        {this.renderAnchor()}
        <span className="sr-only">{alt}</span>
        <img src={src} alt={alt} className={this.getImageClass()} />
      </>
    )
  }
}

export default Content
