import React from "react"
import { CardExpiryElement as BaseCardExpiryElement } from "@stripe/react-stripe-js"

import InputColumn from "./InputGrid/InputColumn"
import InputGroup from "./GroupWithPadding"
import withStripeOptions from "./withStripeOptions"

const CardExpiryElement = withStripeOptions(BaseCardExpiryElement)

const Expiry = () => {
  return (
    <InputColumn>
      <InputGroup label="Exp">
        <CardExpiryElement />
      </InputGroup>
    </InputColumn>
  )
}

export default Expiry
