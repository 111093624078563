import React, { Component } from "react"
import { generateClasses } from "@supercoder.dev/bem-classes"

import Item from "./Item"
import ted from "./ted.png"
import tedIdeas from "./ideas.ted.com.jpg"
import npr from "./npr.jpg"
import tedX from "./ted-x.png"
import abc from "./abc.jpg"
import wondros from "./wondros-logo-black.svg"

export class Items extends Component {
  classes = generateClasses.bind(this)({
    name: "list",
  })

  items = [
    {
      src: tedIdeas,
      alt: "ideas.ted.com",
      href:
        "https://ideas.ted.com/by-saving-this-adorable-elusive-wildcat-you-could-help-save-the-planet-really/",
    },
    {
      src: ted,
      alt: "TED",
      href:
        "https://www.ted.com/talks/ashwin_naidu_the_link_between_fishing_cats_and_mangrove_forest_conservation",
    },
    {
      src: tedX,
      alt: "Ted x",
      href: "https://www.youtube.com/watch?v=ahD6A6o-gws",
    },
    {
      src: npr,
      alt: "PBS and npr",
      href:
        "https://news.azpm.org/p/news-topical-sci/2019/1/31/145390-a-fascination-for-fishing-cats/",
    },
    {
      src: abc,
      alt: "ABC",
      href:
        "https://www.abc.net.au/radionational/programs/archived/thisisabout/the-search-for-the-fishing-cat/9108288",
    },
    {
      src: wondros,
      alt: "Wondros",
      href: "https://youtu.be/PHp66lg_rw0",
    },
  ]

  renderItem = (itemData, index) => {
    return <Item {...itemData} key={index} parentClass={this.classes} />
  }

  renderItems = () => this.items.map(this.renderItem)

  render() {
    return <ul className={this.classes}>{this.renderItems()}</ul>
  }
}

export default Items
