import React from "react"
import {
  createStyles,
  withStyles,
  DialogActions,
  Container,
} from "@material-ui/core"

import GoToBilling from "./GoToBilling"
import Donate from "./Donate"

const styles = () => {
  return createStyles({
    root: {
      padding: "1.75rem 0",
      border: "0.0625rem solid rgba(225, 228, 235, 1)",
    },
  })
}

const Footer = ({
  classes,
  fillOutPaymentDetails,
  setFillOutPaymentDetails,
  amount,
  isAmountValid,
}) => {
  return (
    <DialogActions className={classes.root}>
      <Container>
        {fillOutPaymentDetails ? (
          <Donate amount={amount} />
        ) : (
          <GoToBilling
            setFillOutPaymentDetails={setFillOutPaymentDetails}
            isAmountValid={isAmountValid}
          />
        )}
      </Container>
    </DialogActions>
  )
}

export default withStyles(styles)(Footer)
