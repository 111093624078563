import React from "react"
import { createStyles, withStyles } from "@material-ui/core"

import Text from "../Text"
import Reward from "./Reward"

const styles = () => {
  return createStyles({
    root: {
      marginBottom: "0.2813rem",
      textAlign: "left",
    },
  })
}

const Rewards = ({ rewards, classes }) => {
  const renderReward = (reward, index) => {
    const isLast = rewards.length === index + 1
    return <Reward isLast={isLast}>{reward}</Reward>
  }

  const renderedRewards = rewards.map(renderReward)

  return (
    <div className={classes.root}>
      <Text align="left">

        <p>You'll receive:</p>

      </Text>
      {renderedRewards}
    </div>
  )
}

export default withStyles(styles)(Rewards)
