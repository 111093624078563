import React from "react"
import Section from "../Section"
import teespring from "./teespring.jpg"
import amazonSmile from "./amazon-smile.png"

export default function Links({ parentClass }) {
  return (
    <Section
      items={[
        {
          src: amazonSmile,
          id: "amazon-smile",
          alt: "Amazon Smile",
          href: "https://smile.amazon.com/ch/81-0894559",
          note: "Add us before you shop",
        },
        {
          src: teespring,
          alt: "Teespring",
          id: "teespring",
          href: "https://teespring.com/stores/fishcat",
          note: "Buy our official merch",
        },
      ]}
      parentClass={parentClass}
    />
  )
}
