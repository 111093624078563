import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import LearnMore from "../LearnMore"

export class Header extends Component {
  render() {
    const classes = generateClasses.bind(this)({
      name: "header",
      elements: ["title"],
    })
    return (
      <div className={classes.name}>
        <h1 className={classes.elements.title}>
          Working to protect and restore earth’s most valuable ecosystems
        </h1>
        <LearnMore parentClass={classes.name} />
      </div>
    )
  }
}

export default Header
