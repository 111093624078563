import React, { Component } from "react"

import EmailButton from "../../../components/EmailButton"
import Primary from "./Primary"
import investmentLevels from "../../../constants/investmentLevels"
import InvestmentLevels from "./InvestmentLevels"
import { generateClasses } from "@supercoder.dev/bem-classes"
import Secondary from "./Secondary"
import Seal from "./Seal"

export class Invest extends Component {
  classes = generateClasses({
    name: "invest",
    elements: [
      {
        name: "email",
        elements: ["button"],
      },
    ],
  })

  state = {
    investmentLevel: investmentLevels.tenPlus,
  }

  setInvestmentLevel = investmentLevel => this.setState({ investmentLevel })

  render() {
    const { investmentLevel } = this.state
    return (
      <div>
        <Primary parentClass={this.classes.name} />
        <InvestmentLevels
          parentClass={this.classes.name}
          selectedInvestmentLevel={investmentLevel}
          setInvestmentLevel={this.setInvestmentLevel}
        />
        <Secondary parentClass={this.classes.name} />
        <div className={this.classes.elements.email.name}>
          <span>
            <strong>
              Interested in sending us a check or have a different question?{" "}
            </strong>
            Please <EmailButton />
          </span>
        </div>
        <Seal parentClass={this.classes.name} />
      </div>
    )
  }
}

export default Invest
