import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FullImage from "../components/FullImage"

export default function Cat() {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "2019_HiRes_FishingCat_1_PallaviLaveti" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <FullImage
      fluid={data.file.childImageSharp.fluid}
      credit="Photo: Pallavi Laveti for FishCat.org"
    ></FullImage>
  )
}
