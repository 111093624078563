import React from "react"
const { openMail } = require("../utils/mail")

const Body = ({ html }) => (
  <div className="mt-4">
    <div dangerouslySetInnerHTML={{ __html: html }} />
    <p className="mb-2">
      Please get in touch with your interest and your LinkedIn profile.
    </p>
    <button className="btn btn-secondary mb-3" onClick={openMail}>
      Apply
    </button>
    <p>For more job opportunities, please go to our LinkedIn page.</p>
  </div>
)

export default Body
