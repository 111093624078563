import React, { Component } from "react"
import Investor from "./Investor/index"
import { createStyles, withStyles } from "@material-ui/core"

const styles = theme => {
  return createStyles({
    root: {
      marginLeft: 0,
      padding: 0,
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      },
    },
  })
}

export class Item extends Component {
  renderItem = (investor, index) => {
    return <Investor {...investor} key={index} />
  }

  renderItems = () => {
    const { investors } = this.props
    return investors.map(this.renderItem)
  }

  render() {
    const investors = this.renderItems()
    const { classes } = this.props
    return <ul className={classes.root}>{investors}</ul>
  }
}

export default withStyles(styles)(Item)
