import React from "react"
import { Field } from "redux-form"
import { fields } from "../../../../../../constants/donate"

import InputGroup from "./GroupWithoutPadding"
import TextField from "./TextField"

const Input = props => (
  <TextField {...props} inputProps={{ placeholder: "Name" }} />
)

const NameField = () => <Field name={fields.name} component={Input} />

const Name = () => (
  <InputGroup label="Name">
    <NameField />
  </InputGroup>
)

export default Name
