import React from "react"
import { Field } from "redux-form"
import { fields } from "../../../../../../constants/donate"

import InputGroup from "./GroupWithoutPadding"
import TextField from "./TextField"

const Input = props => (
  <TextField
    {...props}
    inputProps={{ placeholder: "example@gmail.com", type: "email" }}
  />
)

const EmailField = () => <Field name={fields.email} component={Input} />

const Email = () => (
  <InputGroup label="Email">
    <EmailField />
  </InputGroup>
)

export default Email
