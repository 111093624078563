import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Icon extends Component {
  render() {
    const { icon, iconAlt, id } = this.props
    const classes = generateClasses.bind(this)({
      name: "icon-container",
      elements: [
        {
          name: "icon",
          variants: [id],
        },
      ],
    })
    const iconClass = classes.elements.icon.variants[id]
    return (
      <div className={classes.name}>
        <img src={icon} className={iconClass} alt={iconAlt} />
      </div>
    )
  }
}

export default Icon
