import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import { sectionIds } from "../../constants/sectionIds"
import Section from "../../components/Section"
import DownloadReport from "./DownloadReport"


export class Financials extends Component {
  baseClass = "footer"
  render() {
    const className = generateClasses({ name: "financials" })
    return (
      <Section title="Annual Reports" id={sectionIds.financials}>
        <DownloadReport className="reportLinks" parentClass={className} />        
        <div className="mt-3">
          <span className='reportsText'>
          These reports include our Financial Reports, AZCC Reports, and 990 Filings for each year. Prior to 2018 and since mid-2020, we did not receive funding sufficient enough to create and publish full Annual Reports. For any questions and requests for specific reports, please <a href="mailto:info@fishcat.org" style={{ color: 'black', textDecoration: 'underline'}}>email us</a>.
          </span>
        </div>        
      </Section>
    )
  }
}

export default Financials
