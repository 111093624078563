import React from "react"
import { submit } from "redux-form"
import { connect } from "react-redux"

import Button from "./Button"
import { formId } from "../../../../../../constants/donate"

const Donate = ({ amount, dispatch }) => {
  const formattedAmount = `$${amount}`

  const submitForm = () => dispatch(submit(formId))

  const handleClick = () => {
    submitForm()
  }

  return <Button onClick={handleClick}>Donate - {formattedAmount}</Button>
}

export default connect()(Donate)
