import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class DownloadReport extends Component {
  render() {
    const className = generateClasses.bind(this)({
      name: "download",
    })
    return (
      <>
        <div className="reportContainerOuter">
        <div className="reportContainerInner">
            <a
              href="/990N 2023.pdf"
              className={className}
              download="2023 Form 990-N"
            >
              2023 Form 990-N
            </a>
          </div>
          <div className="reportContainerInner">
            <a
              href="/990N 2023.pdf"
              className={className}
              download="2023 Form 990-N"
            >
              2023 Form 990-N
            </a>
          </div>
          <div className="reportContainerInner">
            <a
              href="/990N 2022.pdf"
              className={className}
              download="2022 Form 990-N"
            >
              2022 Form 990-N
            </a>
          </div>
          <div className="reportContainerInner">
            <a
              href="/990N 2021.pdf"
              className={className}
              download="2021 Form 990-N"
            >
              2021 Form 990-N
            </a>
          </div>
          <a
            href="/2020_FishCat_Annual_Report.pdf"
            className={className}
            download="2020 FishCat Annual Report"
          >
            2020 Annual Report
          </a>
        </div>
        <div className="reportContainerInner">
          <a
            href="/2019_FishCat_Annual_Report.pdf"
            className={className}
            download="2019 FishCat Annual Report"
          >
            2019 Annual Report
          </a>
        </div>
        <div className="reportContainerInner">
          <a
            href="/2018_FishCat_Annual_Report.pdf"
            className={className}
            download="2018 FishCat Annual Report"
          >
            2018 Annual Report
          </a>
        </div>
      </>
    )
  }
}

export default DownloadReport
