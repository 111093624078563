import React, { Component } from "react"
import Image from "gatsby-image"
import ImageGroup from "./ImageGroup/index"
import ImageWithCredit from "./ImageWithCredit"

export class FullImage extends Component {
  baseClass = "full-image"

  getImage = () => {
    const { images, fluid } = this.props
    if (fluid) {
      return props => <Image {...props} fluid={fluid} />
    }
    return () => <ImageGroup images={images} parentClass={this.baseClass} />
  }

  render() {
    const { credit, profileLink } = this.props
    const ImageContent = this.getImage()
    return (
      <ImageWithCredit
        credit={credit}
        Image={ImageContent}
        className={this.baseClass}
        profileLink={profileLink}
      />
    )
  }
}

export default FullImage
