import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Description from "./Description"
import Positions from "./Positions"

export class Careers extends Component {
  classes = generateClasses({
    name: "careers",
  })

  render() {
    return (
      <div className={this.classes}>
        <Positions />
        <Description parentClass={this.classes} />
      </div>
    )
  }
}

export default Careers
