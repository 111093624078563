import React from "react"
import Section from "../components/Section"
import GatsbyImage from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import BaseImageWithCredit from "../components/ImageWithCredit"
import { sectionIds } from "../constants/sectionIds"
import ViewReserve from "./LandPartnerships/ViewReserve"

function Image(props) {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "2019_HiRes_FishingCat_1_AnjaniKumar" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return image && <GatsbyImage fluid={image.childImageSharp.fluid} {...props} />
}

const ImageWithCredit = props => (
  <BaseImageWithCredit
    {...props}
    Image={Image}
    credit="Photo: Anjani Kumar for FishCat.org"
  />
)

export default function OurImpact() {
  return (
    <Section
      title="Our Goal"
      Image={ImageWithCredit}
      id={sectionIds.ourImpact}
      Button={ViewReserve}
      isReversed
    >
      <p>
        Is to empower global communities with conservation jobs that restore
        ecosystems, and help solve deforestation, extinction, and climate
        change.
      </p>
      <p>
        We’re working with our landowner partners to create FishCat Nature
        Reserves for sustainability — featured below is one of our Nature
        Reserves 
      </p>
    </Section>
  )
}
