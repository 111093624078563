import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import PositionsSection from "../../../components/JobsList"

export default function Positions() {
  const data = useStaticQuery(graphql`
    fragment JobFields on MarkdownRemarkConnection {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }

    {
      fieldwork: allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "Fieldwork" } }
          fields: { slug: { regex: "/openings/" } }
        }
      ) {
        ...JobFields
      }
      partnership: allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "Partnership" } }
          fields: { slug: { regex: "/openings/" } }
        }
      ) {
        ...JobFields
      }
      outreach: allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "Outreach" } }
          fields: { slug: { regex: "/openings/" } }
        }
      ) {
        ...JobFields
      }
      operation: allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "Operation" } }
          fields: { slug: { regex: "/openings/" } }
        }
      ) {
        ...JobFields
      }
      guidance: allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "Guidance" } }
          fields: { slug: { regex: "/openings/" } }
        }
      ) {
        ...JobFields
      }
    }
  `)
  return <PositionsSection data={data} />
}
