import React from "react"
import { extend } from "../../../utils/classes"

export default function Item({ name, parentClass }) {
  const baseClass = extend(parentClass, "item")
  return (
    <div>
      <span className={baseClass}>{name}</span>
    </div>
  )
}
